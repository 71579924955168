import { createStore } from "vuex";
import persistedStateVuex from "persisted-state-vuex";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "axios";
import router from "@/router";
export default createStore({
  state: {
    isExpandMenu: false,
    getProfileInfo: [],
    getGender: null,
    getRoleUser: null,
    getUserType: null,
    getMobile: null,
    getEmail: null,
    getProfilePath: null,
    isAuthorized: false,
    getUserLog: null,
    getEmployeeId: null,
    getDepartment: null,
    getDesignation: null,
    getIfChangePassword: 1,
    getNotifCounter: 0,
    getNotifSetting: 1,
    getTitleHeaderForm: null,
    errorFileSize: null,
    errorMessage: null,
    errorStatus: false,
    secretTokenKey: null,
    isLogout: false,
    isVisible: false,
    formErrorMessage: null,
    customDate: null,
    currentDate: moment().format("YYYY-MM-DD"),
    creditBalance: 0,
    swalArrayRow: null,
    activityLogs: [],
    isLoadData: true,
    isValue: null,
    authTitlePage: null,
    applicationId: null,
    getPaymentReference: null,
    approverNotifCounter: 0,
    countPaymentRequest: 0,
    countReleaseRequest: 0,
    countLiquidationRequest: 0,
    countPreparationRequest: 0,
    countTravelRequest: 0,
    travelReferenceNumber: 0,
    countTravelCompleted: 0,
    countLeaveRequest: 0,
    countOvertimeRequest: 0,
    countOfficialBusinessRequest: 0,
    countConference: 0,
    employeeMasterList: [],
    NUMBER_FILING_LIMIT: 2, //default : 2
    swalDetailList: [
      {
        sequence: 0,
        alert: "leaveType",
        title: "VL Credit",
        balance: "5",
        message: "Atleast 2 Days prior intended VL",
        image: require("../assets/images/notice.png"),
      },
      {
        sequence: 1,
        alert: "leaveType",
        title: "SL Credit",
        balance: "5.5",
        message: "Within 24 hours after reporting for work",
        image: require("../assets/images/notice.png"),
      },
      {
        sequence: 2,
        alert: "success",
        title: "Successfully Sent!",
        message: "Waiting for approval of immediate head!",
        image: require("../assets/images/success.png"),
      },
      {
        sequence: 3,
        alert: "success",
        title: "Successfully Sent!",
        message: "Waiting for approval",
        image: require("../assets/images/success.png"),
      },
      {
        sequence: 4,
        alert: "success",
        title: "Successfully Added!",
        message: "",
        image: require("../assets/images/success.png"),
      },
      {
        sequence: 5,
        alert: "success",
        title: "Create Event",
        message: "Successfully Published!",
        image: require("../assets/images/success.png"),
      },
      {
        sequence: 6,
        alert: "success",
        title: "Successfully Updated!",
        message: "",
        image: require("../assets/images/success.png"),
      },
    ],
    daysDuration: [],
  },
  getters: {
    message(state) {
      return state.errorMessage;
    },
    isHide(state) {
      return state.isVisible;
    },
    titleForm(state) {
      return state.getTitleHeaderForm;
    },
    customDateVL(state) {
      return state.customDate;
    },
    activityList(state) {
      return state.activityLogs;
    },
    role(state) {
      return state.getRoleUser;
    },
    appExpand(state) {
      return state.isExpandMenu;
    },
  },
  mutations: {
    async logoutHandler(state) {
      state.isLogout = true;
      const formDetails = {
        UserId: state.getEmployeeId,
        action: "Logged out",
      };

      await axios.post(`/api/userlogs`, formDetails);
    },
    errorHandler(state, err) {
      if (err.message === "Network Error") {
        Swal.fire({
          title: "Internal Server Error",
          text: "Sorry, there were some technical issues while processing your request. Please try again later",
          imageUrl: require("../assets/images/internal.png"),
          imageAlt: "Custom image",
        });
        state.isVisible = false;
        state.isLogout = false;
        localStorage.clear();
        return router.push("/login");
      } else {
        let errorDescription = err.response.data.errors[0].detail;
        if (errorDescription === "Internal Server Error") {
          Swal.fire({
            title: "Internal Server Error",
            text: "Sorry, there were some technical issues while processing your request. Please try again later",
            imageUrl: require("../assets/images/internal.png"),
            imageAlt: "Custom image",
          });
          state.isVisible = false;
          state.isLogout = false;
          localStorage.clear();
          return router.push("/login");
        } else if (errorDescription === "Token Expired") {
          setTimeout(function () {
            Swal.fire({
              title: "Session Expired",
              text: "Your session timed out. To continue working, sign in again.",
              imageUrl: require("../assets/images/timeout.png"),
              imageAlt: "Custom image",
            });
            state.isVisible = false;
            state.isLogout = false;
            localStorage.clear();
            return router.push("/login");
          }, 6000);
        } else if (errorDescription === "Unauthorized") {
          setTimeout(function () {
            Swal.fire({
              title: "Unauthorized",
              text: "Access denied due to invalid credential. To continue working, sign in again.",
              imageUrl: require("../assets/images/invalidtoken.png"),
              imageAlt: "Custom image",
            });
            state.isVisible = false;
            state.isLogout = false;
            localStorage.clear();
            return router.push("/login");
          }, 6000);
        } else if (
          err.response.data.errors[0].detail !== "Internal Server Error"
        ) {
          state.errorMessage = err.response.data.errors[0].detail;
        }
      }

      state.errorStatus = true;
    },
    globalDate(state) {
      let curDate = moment().format("dddd");
      if (curDate === "Thursday" || curDate === "Friday") {
        if (
          state.getDepartment === "Customer Services" &&
          state.getDesignation !== "Customer Service Representative - Head"
        ) {
          const ADD_DAYS_INTERVAL = 3;
          let todayDate = moment();
          state.customDate = todayDate
            .add(ADD_DAYS_INTERVAL, "days")
            .format("YYYY-MM-DD");
        } else if (state.getDepartment === "Muntinlupa Clinic") {
          const ADD_DAYS_INTERVAL = 4;
          let todayDate = moment();
          state.customDate = todayDate
            .add(ADD_DAYS_INTERVAL, "days")
            .format("YYYY-MM-DD");
        } else {
          const ADD_DAYS_INTERVAL = 5;
          let todayDate = moment();
          state.customDate = todayDate
            .add(ADD_DAYS_INTERVAL, "days")
            .format("YYYY-MM-DD");
        }
      } else {
        const ADD_DAYS_INTERVAL = 3;
        let todayDate = moment();
        state.customDate = todayDate
          .add(ADD_DAYS_INTERVAL, "days")
          .format("YYYY-MM-DD");
      }
    },
    numberDays(state, start, end) {
      let startDate = moment(start);
      let endDate = moment(end).add(1, "days");

      while (startDate.isBefore(endDate, "days")) {
        let days = startDate.format("dddd");
        startDate.add(1, "days");
        if (days !== "Saturday" && days !== "Sunday") {
          state.daysDuration.push(days);
        }
      }
    },
    noticeAlert(state, val) {
      if (val === "VL") {
        state.swalArrayRow = 0;
        state.creditBalance = state.swalDetailList[state.swalArrayRow].balance;
      } else if (val === "SL") {
        state.swalArrayRow = 1;
        state.creditBalance = state.swalDetailList[state.swalArrayRow].balance;
      } else {
        state.swalArrayRow = null;
        state.creditBalance = 0;
      }
      if (state.swalArrayRow !== null) {
        Swal.fire({
          title: `${state.swalDetailList[state.swalArrayRow].title} : ${
            state.swalDetailList[state.swalArrayRow].balance
          } Day(s)`,
          text: state.swalDetailList[state.swalArrayRow].message,
          imageUrl: state.swalDetailList[state.swalArrayRow].image,
          imageWidth: 150,
          imageHeight: 150,
          imageAlt: "Custom image",
          position: "bottom-end",
          showConfirmButton: false,
          timer: 8500,
        });
      }
    },
    successAlert(state, value) {
      setTimeout(function () {
        state.swalArrayRow = value;
        state.isLoadData = true;

        const reference = state.getPaymentReference
          ? ` with Reference #: ${state.getPaymentReference}`
          : "";

        Swal.fire({
          title: state.swalDetailList[state.swalArrayRow].title,
          text: `${
            state.swalDetailList[state.swalArrayRow].message
          }${reference}`,
          imageUrl: state.swalDetailList[state.swalArrayRow].image,
          imageWidth: 130,
          imageHeight: 130,
          imageAlt: "Custom image",
        });
      }, 100);
    },
    async activityLog(state) {
      const response = await axios.get("/api/getActivityLog");
      state.activityLogs = response.data.data;
    },
    switchAccountHandler(state, userType) {
      if (userType === "admin") {
        localStorage.setItem(`${process.env.VUE_APP_USER_ROLE}_XXX`, "user");
      } else {
        localStorage.setItem(`${process.env.VUE_APP_USER_ROLE}_XXX`, "admin");
      }

      setTimeout(async () => {
        location.reload();
        return router.push("/");
      }, 1500);
    },
    liquidationAlert(state, val) {
      //et dateReleased = moment(val).format("YYYY-MM-DD");
      // let NUMBERS_NOT_INCLUDE_WEEKEND = 0;

      let startDate = moment(val);
      let endDate = moment();
      let daysDuration = [];

      while (startDate.isBefore(endDate, "days")) {
        let days = startDate.format("dddd");
        startDate.add(1, "days");
        if (days !== "Saturday" && days !== "Sunday") {
          daysDuration.push(days);
        }
      }

      let NUMBER_OF_DAYS = 10;
      //Difference in number of days
      let dayRemaining = NUMBER_OF_DAYS - daysDuration.length;

      let message =
        dayRemaining >= 0
          ? `${dayRemaining} working day(s) left to liquidate`
          : `${dayRemaining * -1} working day(s) past due`;

      setTimeout(function () {
        Swal.fire({
          title: "Notice Alert",
          text: `You have ${message}`,
          imageUrl: require("../assets/images/notice.png"),
          imageWidth: 130,
          imageHeight: 130,
          imageAlt: "Custom image",
          position: "bottom-end",
          showConfirmButton: false,
          timer: 11500,
        });
      }, 100);
    },
    fileHandler(state, size) {
      let maxFile = 5000000;
      if (size > maxFile) {
        state.errorFileSize = `File size cannot be larger than 5MB!`;
      } else {
        state.errorFileSize = null;
      }
    },
    async notificationCounterOvertime(state, payload) {
      const response = await axios.get("/api/form/dept");
      let result = response.data;
      let res = result.find((val) => {
        return val.deptName === state.getDepartment;
      });
      let haveSuperVisor = res.haveSuperVisor;

      let itemList;

      if (!payload) {
        const response = await axios.get(`/api/admin/employeee/overtime`);
        itemList = response.data;
      } else {
        itemList = payload;
      }

      let userType = state.getUserType;

      if (userType === "super") {
        state.countOvertimeRequest = itemList.filter((val) => {
          return (
            (!val.status || val.status === "Completion") &&
            val.department === state.getDepartment &&
            val.requestedBy !== state.getUserLog
          );
        }).length;

        state.approverNotifCounter =
          state.countPaymentRequest +
          state.countTravelRequest +
          state.countLeaveRequest +
          state.countOvertimeRequest +
          state.countOfficialBusinessRequest;
      }

      if (userType === "admin-moderator") {
        state.countOvertimeRequest = itemList.filter((val) => {
          return val.status === "Approved" && val.actionStatus === 4;
        }).length;

        state.approverNotifCounter =
          state.countLeaveRequest +
          state.countOvertimeRequest +
          state.countOfficialBusinessRequest;
      }

      if (userType === "head") {
        state.countOvertimeRequest = itemList.filter((val) => {
          if (haveSuperVisor) {
            if (val.User.ifSuperVisor) {
              return (
                (!val.status || val.status === "Completion") &&
                val.department === state.getDepartment &&
                val.requestedBy !== state.getUserLog
              );
            } else {
              return (
                val.status === "Pre-qualified" &&
                val.department === state.getDepartment &&
                val.requestedBy !== state.getUserLog
              );
            }
          } else {
            return (
              (!val.status ||
                val.status === "Completion" ||
                val.status === "Pre-qualified") &&
              val.department === state.getDepartment &&
              val.requestedBy !== state.getUserLog
            );
          }
        }).length;
        state.approverNotifCounter =
          state.countLeaveRequest +
          state.countTravelRequest +
          state.countOfficialBusinessRequest +
          state.countOvertimeRequest;
      }

      if (userType === "avp-head") {
        state.countOvertimeRequest = itemList.filter((val) => {
          return (
            val.status === "Pre-qualified" &&
            val.department === state.getDepartment &&
            (val.empId === 38 || val.empId === 36) &&
            val.requestedBy !== state.getUserLog
          );
        }).length;
        state.approverNotifCounter =
          state.countLeaveRequest +
          state.countTravelRequest +
          state.countOfficialBusinessRequest +
          state.countOvertimeRequest;
      }

      if (userType === "pre-moderator") {
        state.countOvertimeRequest = itemList.filter((val) => {
          return (
            (!val.status ||
              val.status === "Completion" ||
              val.status === "Pre-qualified") &&
            val.department === state.getDepartment &&
            val.requestedBy !== state.getUserLog
          );
        }).length;
        state.approverNotifCounter =
          state.countPaymentRequest +
          state.countPreparationRequest +
          state.countTravelRequest +
          state.countLeaveRequest +
          state.countOvertimeRequest +
          state.countOfficialBusinessRequest;
      }

      if (userType === "semi-moderator") {
        state.countOvertimeRequest = itemList.filter((val) => {
          if (haveSuperVisor) {
            let isSuperVisor = val.User.ifSuperVisor;

            if (isSuperVisor) {
              return (
                val.actionStatus >= 1 &&
                (!val.status || val.status === "Completion") &&
                (val.department === "Astral Clinic" ||
                  val.department === "Corporate Services" ||
                  val.department === "Muntinlupa Clinic") &&
                val.requestedBy !== state.getUserLog
              );
            } else {
              return (
                (val.requestedBy !== state.getUserLog &&
                  val.actionStatus >= 1 &&
                  (!val.status || val.status === "Completion") &&
                  val.department === "Corporate Services" &&
                  val.designation !== "Messenger" &&
                  val.designation !== "Company Driver" &&
                  val.designation !== "Utility Worker" &&
                  val.designation !== "Policy Administration Assistant" &&
                  val.designation !== "Policy and Admin Support Staff" &&
                  val.designation !== "Policy Insurance Coordinator" &&
                  val.designation !== "Admin Support Assistant") ||
                (val.actionStatus > 2 &&
                  val.status === "Pre-qualified" &&
                  val.department === "Astral Clinic" &&
                  val.requestedBy !== state.getUserLog) ||
                (val.requestedBy !== state.getUserLog &&
                  val.actionStatus > 2 &&
                  val.status === "Pre-qualified" &&
                  val.department === "Corporate Services" &&
                  (val.designation === "Messenger" ||
                    val.designation === "Company Driver" ||
                    val.designation === "Utility Worker"))
              );
            }
          }
          //
        }).length;
        state.approverNotifCounter =
          state.countPaymentRequest +
          state.countLeaveRequest +
          state.countOvertimeRequest +
          state.countOfficialBusinessRequest;
      }

      if (userType === "moderator") {
        state.countOvertimeRequest = itemList.filter((val) => {
          if (haveSuperVisor) {
            if (val.department === "Marketing") {
              return (
                (!val.status ||
                  val.status === "Completion" ||
                  val.status === "Pre-qualified") &&
                val.department === "Marketing" &&
                val.requestedBy !== state.getUserLog
              );
            }
            if (
              val.department === "Health Provider & Patient Relations" &&
              (val.designation ===
                "Patient Relations Coordinator - Supervisor" ||
                val.designation === "Health Provider Relations - Supervisor")
            ) {
              return (
                (!val.status ||
                  val.status === "Completion" ||
                  val.status === "Pre-qualified") &&
                val.department === state.getDepartment &&
                (val.designation ===
                  "Patient Relations Coordinator - Supervisor" ||
                  val.designation ===
                    "Health Provider Relations - Supervisor") &&
                val.requestedBy !== state.getUserLog
              );
            }
            if (val.department === "Health Provider & Patient Relations") {
              return (
                (val.status === "Completion" ||
                  val.status === "Pre-qualified") &&
                val.department === state.getDepartment &&
                val.requestedBy !== state.getUserLog
              );
            }
          }
        }).length;
        state.approverNotifCounter =
          state.countTravelRequest +
          state.countLeaveRequest +
          state.countOvertimeRequest +
          state.countOfficialBusinessRequest;
      }
      if (userType === "supervisor-moderator") {
        state.countOvertimeRequest = itemList.filter((val) => {
          return (
            (!val.status || val.status === "Completion") &&
            val.department === state.getDepartment &&
            val.requestedBy !== state.getUserLog
          );
        }).length;

        state.approverNotifCounter =
          state.countLeaveRequest +
          state.countOvertimeRequest +
          state.countOfficialBusinessRequest;
      }
      if (
        userType === "cohead-moderator" ||
        userType === "head-supervisor" ||
        userType === "operator-moderator"
      ) {
        state.countOvertimeRequest = itemList.filter((val) => {
          if (val.department === state.getDepartment) {
            let designation = state.getProfileInfo.designation;

            if (designation === "Health Provider Relations - Supervisor") {
              userType =
                (!val.status || val.status === "Completion") &&
                val.designation === "Health Provider Relations Officer" &&
                val.requestedBy !== state.getUserLog;
            } else if (
              designation === "Patient Relations Coordinator - Supervisor"
            ) {
              userType =
                (!val.status || val.status === "Completion") &&
                val.designation !== "Health Provider Relations Officer" &&
                val.designation !== "Health Provider Relations - Supervisor" &&
                val.requestedBy !== state.getUserLog;
            } else if (
              designation === "Facilities and Properties, GSD Supervisor"
            ) {
              userType =
                (!val.status || val.status === "Completion") &&
                (val.designation === "Messenger" ||
                  val.designation === "Company Driver" ||
                  val.designation === "Utility Worker" ||
                  val.designation === "Admin Support Assistant") &&
                val.requestedBy !== state.getUserLog;
            } else if (designation === "Policy and Claims Department - Head") {
              userType =
                (!val.status || val.status === "Completion") &&
                (val.designation === "Policy Administration Assistant" ||
                  val.designation === "Policy and Admin Support Staff" ||
                  val.designation === "Policy Insurance Coordinator") &&
                val.requestedBy !== state.getUserLog;
            } else {
              userType =
                (!val.status || val.status === "Completion") &&
                val.department === state.getDepartment &&
                val.requestedBy !== state.getUserLog;
            }
            return userType;
          }
        }).length;
        state.approverNotifCounter =
          state.countLeaveRequest +
          state.countTravelRequest +
          state.countOvertimeRequest +
          state.countOfficialBusinessRequest;
      }
    },
    async notificationCounterOfficialBusiness(state, payload) {
      const response = await axios.get("/api/form/dept");
      let result = response.data;
      let res = result.find((val) => {
        return val.deptName === state.getDepartment;
      });
      let haveSuperVisor = res.haveSuperVisor;

      let itemList;

      if (!payload) {
        const response = await axios.get(`/api/admin/employeee/ob`);
        itemList = response.data;
      } else {
        itemList = payload;
      }

      let userType = state.getUserType;

      if (userType === "super") {
        state.countOfficialBusinessRequest = itemList.filter((val) => {
          return (
            (!val.status || val.status === "Completion") &&
            (val.isDirect || val.department === "Executive Office") &&
            val.requestedBy !== state.getUserLog
          );
        }).length;

        state.approverNotifCounter =
          state.countPaymentRequest +
          state.countTravelRequest +
          state.countLeaveRequest +
          state.countOvertimeRequest +
          state.countOfficialBusinessRequest;
      }

      if (userType === "admin-moderator") {
        state.countOfficialBusinessRequest = itemList.filter((val) => {
          return val.status === "Approved" && val.actionStatus === 4;
        }).length;

        state.approverNotifCounter =
          state.countLeaveRequest +
          state.countOvertimeRequest +
          state.countOfficialBusinessRequest;
      }

      if (userType === "head") {
        state.countOfficialBusinessRequest = itemList.filter((val) => {
          if (haveSuperVisor) {
            if (val.User.ifSuperVisor) {
              return (
                (!val.status || val.status === "Completion") &&
                val.department === state.getDepartment &&
                val.requestedBy !== state.getUserLog
              );
            } else {
              return (
                val.status === "Pre-qualified" &&
                val.department === state.getDepartment &&
                val.requestedBy !== state.getUserLog
              );
            }
          } else {
            return (
              (!val.status ||
                val.status === "Completion" ||
                val.status === "Pre-qualified") &&
              val.department === state.getDepartment &&
              val.requestedBy !== state.getUserLog
            );
          }
        }).length;
        state.approverNotifCounter =
          state.countLeaveRequest +
          state.countTravelRequest +
          state.countOfficialBusinessRequest +
          state.countOvertimeRequest;
      }

      if (userType === "pre-moderator") {
        state.countOfficialBusinessRequest = itemList.filter((val) => {
          return (
            (!val.status ||
              val.status === "Completion" ||
              val.status === "Pre-qualified") &&
            val.department === state.getDepartment &&
            val.requestedBy !== state.getUserLog
          );
        }).length;
        state.approverNotifCounter =
          state.countPaymentRequest +
          state.countPreparationRequest +
          state.countTravelRequest +
          state.countLeaveRequest +
          state.countOvertimeRequest +
          state.countOfficialBusinessRequest;
      }

      if (userType === "semi-moderator") {
        state.countOfficialBusinessRequest = itemList.filter((val) => {
          if (haveSuperVisor) {
            let isSuperVisor = val.User.ifSuperVisor;

            if (isSuperVisor) {
              return (
                val.actionStatus >= 1 &&
                (!val.status || val.status === "Completion") &&
                (val.department === "Astral Clinic" ||
                  val.department === "Corporate Services" ||
                  val.department === "Muntinlupa Clinic") &&
                val.requestedBy !== state.getUserLog
              );
            } else {
              return (
                (val.requestedBy !== state.getUserLog &&
                  val.actionStatus >= 1 &&
                  (!val.status || val.status === "Completion") &&
                  val.department === "Corporate Services" &&
                  val.designation !== "Messenger" &&
                  val.designation !== "Company Driver" &&
                  val.designation !== "Utility Worker" &&
                  val.designation !== "Admin Support Assistant") ||
                (val.actionStatus > 2 &&
                  val.status === "Pre-qualified" &&
                  val.department === "Astral Clinic" &&
                  val.requestedBy !== state.getUserLog) ||
                (val.requestedBy !== state.getUserLog &&
                  val.actionStatus > 2 &&
                  val.status === "Pre-qualified" &&
                  val.department === "Corporate Services" &&
                  (val.designation === "Messenger" ||
                    val.designation === "Company Driver" ||
                    val.designation === "Utility Worker" ||
                    val.designation === "Admin Support Assistant"))
              );
            }
          }
          //
        }).length;
        state.approverNotifCounter =
          state.countPaymentRequest +
          state.countLeaveRequest +
          state.countOfficialBusinessRequest +
          state.countOvertimeRequest;
      }

      if (userType === "moderator") {
        state.countOfficialBusinessRequest = itemList.filter((val) => {
          if (haveSuperVisor) {
            if (val.department === "Marketing") {
              return (
                (!val.status ||
                  val.status === "Completion" ||
                  val.status === "Pre-qualified") &&
                val.department === "Marketing" &&
                val.requestedBy !== state.getUserLog
              );
            }
            if (
              val.department === "Health Provider & Patient Relations" &&
              (val.designation ===
                "Patient Relations Coordinator - Supervisor" ||
                val.designation === "Health Provider Relations - Supervisor")
            ) {
              return (
                (!val.status ||
                  val.status === "Completion" ||
                  val.status === "Pre-qualified") &&
                val.department === state.getDepartment &&
                (val.designation ===
                  "Patient Relations Coordinator - Supervisor" ||
                  val.designation ===
                    "Health Provider Relations - Supervisor") &&
                val.requestedBy !== state.getUserLog
              );
            }
            if (val.department === "Health Provider & Patient Relations") {
              return (
                (val.status === "Completion" ||
                  val.status === "Pre-qualified") &&
                val.department === state.getDepartment &&
                val.requestedBy !== state.getUserLog
              );
            }
          }
        }).length;
        state.approverNotifCounter =
          state.countTravelRequest +
          state.countLeaveRequest +
          state.countOvertimeRequest +
          state.countOfficialBusinessRequest;
      }
      if (userType === "supervisor-moderator") {
        state.countOfficialBusinessRequest = itemList.filter((val) => {
          return (
            (!val.status || val.status === "Completion") &&
            val.department === state.getDepartment &&
            val.requestedBy !== state.getUserLog
          );
        }).length;

        state.approverNotifCounter =
          state.countLeaveRequest +
          state.countOvertimeRequest +
          state.countOfficialBusinessRequest;
      }

      if (
        userType === "cohead-moderator" ||
        userType === "head-supervisor" ||
        userType === "operator-moderator"
      ) {
        state.countOfficialBusinessRequest = itemList.filter((val) => {
          if (val.department === state.getDepartment) {
            let designation = state.getProfileInfo.designation;

            if (designation === "Health Provider Relations - Supervisor") {
              userType =
                (!val.status || val.status === "Completion") &&
                val.designation === "Health Provider Relations Officer" &&
                val.requestedBy !== state.getUserLog;
            } else if (
              designation === "Patient Relations Coordinator - Supervisor"
            ) {
              userType =
                (!val.status || val.status === "Completion") &&
                val.designation !== "Health Provider Relations Officer" &&
                val.designation !== "Health Provider Relations - Supervisor" &&
                val.requestedBy !== state.getUserLog;
            } else if (
              designation === "Facilities and Properties, GSD Supervisor"
            ) {
              userType =
                (!val.status || val.status === "Completion") &&
                (val.designation === "Messenger" ||
                  val.designation === "Company Driver" ||
                  val.designation === "Utility Worker" ||
                  val.designation === "Admin Support Assistant") &&
                val.requestedBy !== state.getUserLog;
            } else if (designation === "Policy and Claims Department - Head") {
              userType =
                (!val.status || val.status === "Completion") &&
                (val.designation === "Policy Administration Assistant" ||
                  val.designation === "Policy and Admin Support Staff" ||
                  val.designation === "Policy Insurance Coordinator") &&
                val.requestedBy !== state.getUserLog;
            } else {
              userType =
                (!val.status || val.status === "Completion") &&
                val.department === state.getDepartment &&
                val.requestedBy !== state.getUserLog;
            }
            return userType;
          }
        }).length;
        state.approverNotifCounter =
          state.countTravelRequest +
          state.countLeaveRequest +
          state.countOfficialBusinessRequest +
          state.countOvertimeRequest;
      }
    },
    async notificationCounterLeave(state, payload) {
      let itemList;

      if (!payload) {
        const response = await axios.get(`api/admin/employee/leave`);
        itemList = response.data;
      } else {
        itemList = payload;
      }

      let userType = state.getUserType;

      if (userType === "super") {
        state.countLeaveRequest = itemList.filter((val) => {
          return (
            !val.approverStatus &&
            (val.isDirect || val.department === "Executive Office")
          );
        }).length;

        state.approverNotifCounter =
          state.countPaymentRequest +
          state.countTravelRequest +
          state.countLeaveRequest +
          state.countOvertimeRequest +
          state.countOfficialBusinessRequest;
      }

      if (userType === "head") {
        state.countLeaveRequest = itemList.filter((val) => {
          return (
            !val.approverStatus &&
            val.department === state.getDepartment &&
            val.requestedBy !== state.getUserLog
          );
        }).length;

        state.approverNotifCounter =
          state.countLeaveRequest +
          state.countTravelRequest +
          state.countOfficialBusinessRequest +
          state.countOvertimeRequest;
      }

      if (userType === "avp-head") {
        state.countLeaveRequest = itemList.filter((val) => {
          return (
            !val.approverStatus &&
            val.department === state.getDepartment &&
            val.requestedBy !== state.getUserLog
          );
        }).length;

        state.approverNotifCounter =
          state.countLeaveRequest +
          state.countTravelRequest +
          state.countOfficialBusinessRequest +
          state.countOvertimeRequest;
      }

      if (userType === "admin-moderator") {
        state.countLeaveRequest = itemList.filter((val) => {
          return val.isPreapproved && val.approverStatus === "Pre-approved";
        }).length;

        state.approverNotifCounter =
          state.countLeaveRequest +
          state.countOvertimeRequest +
          state.countOfficialBusinessRequest;
      }
      if (userType === "moderator") {
        state.countLeaveRequest = itemList.filter((val) => {
          return (
            !val.approverStatus &&
            (val.department === "Health Provider & Patient Relations" ||
              val.department === "Marketing") &&
            val.requestedBy !== state.getUserLog
          );
        }).length;

        state.approverNotifCounter =
          state.countTravelRequest +
          state.countLeaveRequest +
          state.countOvertimeRequest +
          state.countOfficialBusinessRequest;
      }
      if (userType === "semi-moderator") {
        state.countLeaveRequest = itemList.filter((val) => {
          return (
            !val.approverStatus &&
            (val.department === "Corporate Services" ||
              val.department === "Astral Clinic" ||
              val.empId === 72) &&
            val.User.designation !== "Messenger" &&
            val.User.designation !== "Company Driver" &&
            val.User.designation !== "Utility Worker" &&
            val.requestedBy !== state.getUserLog
          );
        }).length;

        state.approverNotifCounter =
          state.countPaymentRequest +
          state.countLeaveRequest +
          state.countOvertimeRequest +
          state.countOfficialBusinessRequest;
      }

      if (userType === "operator-moderator") {
        state.countLeaveRequest = itemList.filter((val) => {
          return (
            !val.approverStatus &&
            val.department === "Corporate Services" &&
            (val.User.designation === "Messenger" ||
              val.User.designation === "Company Driver" ||
              val.User.designation === "Utility Worker" ||
              val.User.designation === "Admin Support Assistant") &&
            val.requestedBy !== state.getUserLog
          );
        }).length;

        state.approverNotifCounter =
          state.countLeaveRequest +
          state.countTravelRequest +
          state.countOvertimeRequest +
          state.countOfficialBusinessRequest;
      }
      if (userType === "head-supervisor") {
        state.countLeaveRequest = itemList.filter((val) => {
          return (
            !val.approverStatus &&
            state.getDepartment === "Corporate Services" &&
            (val.User.designation === "Policy Administration Assistant" ||
              val.User.designation === "Policy and Admin Support Staff" ||
              val.User.designation === "Policy Insurance Coordinator") &&
            val.requestedBy !== state.getUserLog
          );
        }).length;

        state.approverNotifCounter =
          state.countLeaveRequest +
          state.countTravelRequest +
          state.countOvertimeRequest +
          state.countOfficialBusinessRequest;
      }

      if (userType === "supervisor-moderator") {
        state.countLeaveRequest = itemList.filter((val) => {
          return (
            !val.approverStatus &&
            val.department === "Muntinlupa Clinic" &&
            val.requestedBy !== state.getUserLog
          );
        }).length;

        state.approverNotifCounter =
          state.countLeaveRequest +
          state.countOvertimeRequest +
          state.countOfficialBusinessRequest;
      }

      if (userType === "pre-moderator") {
        state.countLeaveRequest = itemList.filter((val) => {
          return (
            !val.approverStatus &&
            val.department === "Accounting" &&
            val.requestedBy !== state.getUserLog
          );
        }).length;

        state.approverNotifCounter =
          state.countPaymentRequest +
          state.countPreparationRequest +
          state.countTravelRequest +
          state.countLeaveRequest +
          state.countOvertimeRequest +
          state.countOfficialBusinessRequest;
      }
    },
    async notificationCounterTravel(state) {
      const response = await axios.get(`/api/admin/travel/request`);
      let itemList = response.data;
      let userType = state.getUserType;

      if (userType === "head") {
        state.countTravelRequest = itemList.filter((val) => {
          return (
            !val.status &&
            val.department === state.getDepartment &&
            val.requestedBy !== state.getUserLog
          );
        }).length;

        state.approverNotifCounter =
          state.countLeaveRequest +
          state.countTravelRequest +
          state.countOfficialBusinessRequest +
          state.countOvertimeRequest;
      }
      if (userType === "moderator") {
        state.countTravelRequest = itemList.filter((val) => {
          return (
            !val.status &&
            (val.department === "Health Provider & Patient Relations" ||
              val.department === "Marketing") &&
            val.requestedBy !== state.getUserLog
          );
        }).length;

        state.approverNotifCounter =
          state.countTravelRequest +
          state.countLeaveRequest +
          state.countOvertimeRequest +
          state.countOfficialBusinessRequest;
      }
      if (userType === "pre-moderator") {
        state.countTravelRequest = itemList.filter((val) => {
          return (
            !val.status &&
            val.department === "Accounting" &&
            val.requestedBy !== state.getUserLog
          );
        }).length;
        state.approverNotifCounter =
          state.countPaymentRequest +
          state.countPreparationRequest +
          state.countTravelRequest +
          state.countLeaveRequest +
          state.countOvertimeRequest +
          state.countOfficialBusinessRequest;
      }
      if (userType === "super") {
        state.countTravelRequest = itemList.filter((val) => {
          return (
            !val.status &&
            (val.department === "Executive Office" || val.isDirect)
          );
        }).length;

        state.approverNotifCounter =
          state.countPaymentRequest +
          state.countTravelRequest +
          state.countLeaveRequest +
          state.countOvertimeRequest +
          state.countOfficialBusinessRequest;
      }

      if (userType === "driver-moderator") {
        state.countTravelCompleted = itemList.filter((val) => {
          return val.status === "Completed" && val.driver === state.getUserLog;
        }).length;

        state.countTravelRequest = itemList.filter((val) => {
          return (
            (val.status === "Approved" || val.status === "Transferred") &&
            val.driver === state.getUserLog
          );
        }).length;

        state.approverNotifCounter = state.countTravelRequest;
      }
      if (userType === "operator-moderator") {
        state.countTravelRequest = itemList.filter((val) => {
          return (
            val.status === "Checked" ||
            (val.department === "Corporate Services" && !val.status) ||
            (val.department === "Astral Clinic" && !val.status) ||
            (val.department === "Muntinlupa Clinic" && !val.status)
          );
        }).length;

        state.countTravelCompleted = itemList.filter((val) => {
          return val.status === "Completed";
        }).length;

        state.approverNotifCounter =
          state.countLeaveRequest +
          state.countTravelRequest +
          state.countOvertimeRequest +
          state.countOfficialBusinessRequest;
      }
    },
    async notificationCounterReservation(state) {
      const response = await axios.get(`/api/admin/conference/reservation`);
      let itemList = response.data;
      let userType = state.getUserType;

      if (userType === "checker-moderator") {
        state.countConference = itemList.filter((val) => {
          return !val.status;
        }).length;

        state.approverNotifCounter =
          state.countConference + state.countPaymentRequest;
      }
    },
    notificationCounter(state, data) {
      let userType = state.getUserType;
      let itemList = data;

      //Super Count
      if (userType === "super") {
        state.countPaymentRequest = itemList.filter((val) => {
          return val.statusRequest === "Pre-approved";
        }).length;
        state.approverNotifCounter =
          state.countPaymentRequest +
          state.countTravelRequest +
          state.countLeaveRequest +
          state.countOvertimeRequest +
          state.countOfficialBusinessRequest;
      }

      if (userType === "moderator") {
        state.countPaymentRequest = itemList.filter((val) => {
          return val.approver === userType && val.statusRequest === "Pending";
        }).length;

        state.approverNotifCounter =
          state.countTravelRequest +
          state.countLeaveRequest +
          state.countOvertimeRequest +
          state.countOfficialBusinessRequest;
      } else if (userType === "semi-moderator") {
        state.countPaymentRequest = itemList.filter((val) => {
          return val.approver === userType && val.statusRequest === "Pending";
        }).length;

        state.approverNotifCounter =
          state.countPaymentRequest +
          state.countLeaveRequest +
          state.countOvertimeRequest +
          state.countOfficialBusinessRequest;
      } else if (userType === "pre-moderator") {
        state.countPaymentRequest = itemList.filter((val) => {
          return val.approver === userType && val.statusRequest === "Pending";
        }).length;

        state.countPreparationRequest = itemList.filter((val) => {
          return (
            val.statusRequest === "Approved" && val.statusProcessed === null
          );
        }).length;

        state.approverNotifCounter =
          state.countPaymentRequest +
          state.countPreparationRequest +
          state.countTravelRequest +
          state.countLeaveRequest +
          state.countOvertimeRequest +
          state.countOfficialBusinessRequest;
      } else if (userType === "post-moderator") {
        state.countPreparationRequest = itemList.filter((val) => {
          return val.statusProcessed === "For check preparation";
        }).length;

        state.approverNotifCounter = state.countPreparationRequest;
      } else if (userType === "distributed-moderator") {
        if (state.authTitlePage !== "Payment Liquidation") {
          state.countReleaseRequest = itemList.filter((val) => {
            let expenseCategory = val.expenseDetails[0].name;

            return (
              expenseCategory !== "UTILIZATION (DOCTORS)" &&
              expenseCategory !== "UTILIZATION (HOSPITALS)" &&
              expenseCategory !== "UTILIZATION (CLINICS)" &&
              expenseCategory !== "UTILIZATION (SUBSIDY)" &&
              expenseCategory !== "UTILIZATION (CASH BOND)" &&
              val.statusProcessed === "Forwarded to Treasury"
            );
          }).length;
        }

        state.countLiquidationRequest = itemList.filter((val) => {
          return (
            val.liquidatedStatus === "For OR preparation" ||
            val.liquidatedStatus === "For Reimbursement"
          );
        }).length;

        state.approverNotifCounter =
          state.countReleaseRequest + state.countLiquidationRequest;
      } else if (userType === "dispatch-moderator") {
        state.countReleaseRequest = itemList.filter((val) => {
          let expenseCategory = val.expenseDetails[0].name;

          return (
            (val.statusProcessed === "Forwarded to Treasury") &
            (expenseCategory === "UTILIZATION (HOSPITALS)" ||
              expenseCategory === "UTILIZATION (CLINICS)" ||
              expenseCategory === "UTILIZATION (DOCTORS)" ||
              expenseCategory === "UTILIZATION (SUBSIDY)" ||
              expenseCategory === "UTILIZATION (CASH BOND)")
          );
        }).length;

        state.approverNotifCounter = state.countReleaseRequest;
      } else if (userType === "reactive-moderator") {
        state.countLiquidationRequest = itemList.filter((val) => {
          return val.liquidationDated && !val.liquidatedStatus;
        }).length;

        state.approverNotifCounter = state.countLiquidationRequest;
      } else if (userType === "checker-moderator") {
        state.countPaymentRequest = itemList.filter((val) => {
          return val.approver === "super" && val.statusRequest === "Pending";
        }).length;

        state.approverNotifCounter =
          state.countPaymentRequest + state.countConference;
      }
    },
    async travelReference(state, val) {
      const response = await axios.get("/api/form/dept");
      const departmentList = response.data;

      const getCode = departmentList.filter((item) => {
        return item.deptName === val.department;
      });
      let code = getCode[0].deptCode;
      const itemList = val;

      state.travelReferenceNumber = `${code}-${itemList.id + 100000}`;
    },
    async fetchProfileHandler(state, link) {
      try {
        let pathProfile = link;
        localStorage.setItem(
          `${process.env.VUE_APP_PROFILE_IMAGE}_XXX`,
          pathProfile
        );
        state.getProfilePath = localStorage.getItem(
          `${process.env.VUE_APP_PROFILE_IMAGE}_XXX`
        );
      } catch (error) {
        console.log(error);
      }
    },
    async fetchDTRAttendance(state, dataItems) {
      let dateEnd = dataItems.dateEnd;
      let timeStart = dataItems.timeStart;
      let employeeNumber = dataItems.employeeno;
      let result = await axios.get(`api/dtrs/${employeeNumber}`);
      result.data
        .filter((val) => {
          return val.datelog === dateEnd;
        })
        .filter((val, index) => {
          if (index === 0 && val.io === "O") {
            return val;
          } else {
            return val.timelog > timeStart;
          }
        });
    },
  },
  actions: {},
  modules: {},
  plugins: [persistedStateVuex.init],
});
