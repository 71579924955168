<template>
  <div class="setting-body">
    <div
      class="version-container"
      v-for="(item, index) in versionList.slice(0, counterPage)"
      :key="index"
    >
      <div class="version-header">
        <h3>{{ item.version }}</h3>
        <div class="version-date">{{ dateFormat(item.released) }}</div>
      </div>
      <div class="version-details">
        <ul v-if="item.features.length">
          <h4>What's new:</h4>
          <li v-for="description in item.features" :key="description">
            {{ description }}
          </li>
        </ul>
        <ul v-if="item.bugs.length">
          <h4>Bug fix:</h4>
          <li v-for="bugs in item.bugs" :key="bugs">
            {{ bugs }}
          </li>
        </ul>
      </div>

      <hr v-show="index + 1 !== versionList.length" />
    </div>
    <div class="version-btn" v-if="versionList.length > maxLimit">
      <div>
        <input
          class="btn-load-more"
          type="button"
          value="Load More"
          @click="loadCounter()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, inject } from "vue";
export default {
  setup() {
    const moment = inject("$moment");
    const counterPage = ref(3);
    const showPerPage = ref(3);
    const counter = ref(1);
    const maxLimit = ref(0);
    const versionList = reactive([
      {
        version: "V.6.7.125",
        released: "2025-03-27 11:30:00",
        features: [],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.6.7.124",
        released: "2025-03-25 08:00:00",
        features: ["Customer Service Realignment Approver"],
        bugs: [],
      },
      {
        version: "V.6.7.123",
        released: "2025-02-07 16:30:00",
        features: ["Policy Realignment Approver"],
        bugs: [],
      },
      {
        version: "V.6.7.122",
        released: "2024-11-06 09:40:00",
        features: [],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.6.7.121",
        released: "2024-10-15 14:00:00",
        features: ["Added DV Number in Liquidation Details"],
        bugs: [],
      },
      {
        version: "V.6.7.120",
        released: "2024-07-19 10:40:00",
        features: [],
        bugs: ["Fixed Overtime Approval bugs"],
      },
      {
        version: "V.6.7.119",
        released: "2024-07-10 15:40:00",
        features: [],
        bugs: ["Fixed Internal Services Error"],
      },
      {
        version: "V.6.7.118",
        released: "2024-06-03 10:00:00",
        features: [],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.6.7.117",
        released: "2024-05-29 14:30:00",
        features: [],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.6.7.116",
        released: "2024-05-22 14:00:00",
        features: [],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.6.7.115",
        released: "2024-05-09 15:00:00",
        features: ["Added file attachment view"],
        bugs: [],
      },
      {
        version: "V.6.7.114",
        released: "2024-04-30 15:00:00",
        features: ["Added reupload file in PRF"],
        bugs: [],
      },
      {
        version: "V.6.7.113",
        released: "2024-04-23 14:00:00",
        features: [],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.6.7.112",
        released: "2024-04-15 22:00:00",
        features: ["Added Badges Notification by Form"],
        bugs: [],
      },
      {
        version: "V.6.7.111",
        released: "2024-04-14 01:00:00",
        features: [],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.6.7.110",
        released: "2024-04-11 14:00:00",
        features: [],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.6.7.109",
        released: "2024-03-26 09:20:00",
        features: [],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.6.7.108",
        released: "2024-03-19 10:00:00",
        features: ["Added remark in realeasing portal"],
        bugs: [],
      },
      {
        version: "V.6.7.107",
        released: "2024-03-12 16:21:00",
        features: [],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.6.7.106",
        released: "2024-03-08 10:21:00",
        features: [],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.6.7.105",
        released: "2024-03-06 17:00:00",
        features: [],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.6.7.104",
        released: "2024-02-29 12:00:00",
        features: [],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.6.7.103",
        released: "2024-02-26 11:40:00",
        features: [],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.6.7.102",
        released: "2024-02-23 16:50:00",
        features: [],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.6.7.101",
        released: "2024-02-21 10:25:00",
        features: [],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.6.7.100",
        released: "2024-02-16 12:00:00",
        features: [],
        bugs: ["Changes filing OT with OB procedure"],
      },
      {
        version: "V.6.7.99",
        released: "2024-02-14 10:30:00",
        features: [],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.6.7.98",
        released: "2024-02-12 16:00:00",
        features: [],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.6.7.97",
        released: "2024-02-10 14:30:00",
        features: ["Added Overtime Reports"],
        bugs: ["Fixed OB Disapproved"],
      },
      {
        version: "V.6.7.96",
        released: "2024-02-09 17:30:00",
        features: [],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.6.7.95",
        released: "2024-02-08 09:30:00",
        features: [],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.6.7.94",
        released: "2024-02-07 14:00:00",
        features: [],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.6.7.93",
        released: "2024-02-06 00:00:00",
        features: [],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.6.7.92",
        released: "2024-02-04 21:40:00",
        features: ["Added Overtime Reports"],
        bugs: [],
      },
      {
        version: "V.6.7.91",
        released: "2024-02-02 18:40:00",
        features: [],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.6.7.90",
        released: "2024-01-31 09:15:00",
        features: ["Added Overtime and OB transaction history"],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.6.7.89",
        released: "2024-01-31 07:15:00",
        features: [],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.6.7.88",
        released: "2024-01-30 11:30:00",
        features: [],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.6.7.87",
        released: "2024-01-28 19:00:00",
        features: [],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.6.7.86",
        released: "2024-01-28 01:00:00",
        features: [],
        bugs: ["fixed counter notification"],
      },
      {
        version: "V.6.7.85",
        released: "2024-01-27 05:30:00",
        features: ["Added OB Form"],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.6.7.84",
        released: "2024-01-26 07:00:00",
        features: ["Added OT Form"],
        bugs: [],
      },
      {
        version: "V.4.6.83",
        released: "2024-01-08 16:52:00",
        features: ["Leave form-approver transition for Corporate Services"],
        bugs: [],
      },
      {
        version: "V.4.6.82",
        released: "2023-11-20 22:15:00",
        features: [],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.4.6.81",
        released: "2023-11-14 14:00:00",
        features: [],
        bugs: ["Fixed schedule of muntinlupa"],
      },
      {
        version: "V.4.6.80",
        released: "2023-11-13 14:00:00",
        features: [],
        bugs: ["Fixed shifting schedule display"],
      },
      {
        version: "V.4.6.79",
        released: "2023-11-10 11:00:00",
        features: ["Added employees reset password in HR portal"],
        bugs: [],
      },
      {
        version: "V.4.6.78",
        released: "2023-11-09 14:20:00",
        features: ["Leave Form - Added emergency types"],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.4.6.77",
        released: "2023-11-03 21:22:00",
        features: [
          "Super and HR dashboard - Added synchronize biotime",
          "Driver Portal - Enhance TRF Information details",
        ],
        bugs: ["Optimized layout design"],
      },
      {
        version: "V.4.6.76",
        released: "2023-11-01 13:25:00",
        features: ["Employee Masterlist - Added filtering on duty"],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.4.6.75",
        released: "2023-10-31 19:45:00",
        features: [],
        bugs: [
          "Optimized layout design",
          "Integrate work schedule in leave form",
          "Bugs fixes and perfomance improvements",
        ],
      },
      {
        version: "V.4.6.74",
        released: "2023-10-26 16:00:00",
        features: ["Added Shifting Scheduled leave form"],
        bugs: [],
      },
      {
        version: "V.4.6.73",
        released: "2023-10-25 16:45:00",
        features: ["Added Shifting Schedule Encoding", "Added employee status"],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.4.6.72",
        released: "2023-10-24 09:30:00",
        features: [],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.4.6.71",
        released: "2023-10-20 16:00:00",
        features: ["Added Employee Schedule"],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.4.6.70",
        released: "2023-10-13 13:50:00",
        features: ["Enhance Cash advance PRF"],
        bugs: [
          "Fixed Calendar Dashboard",
          "Fixed download attachment file",
          "Bugs fixes and perfomance improvements",
        ],
      },
      {
        version: "V.4.6.69",
        released: "2023-10-10 14:30:00",
        features: [],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.4.6.68",
        released: "2023-10-09 14:30:00",
        features: [],
        bugs: ["Fixed logged in attempt bugs"],
      },
      {
        version: "V.4.6.67",
        released: "2023-10-05 10:10:00",
        features: [],
        bugs: ["Account Settings- Reduce time interval profile upload"],
      },
      {
        version: "V.4.6.66",
        released: "2023-10-04 15:40:00",
        features: [
          "Employee Masterlist- Added Edit Profile",
          "Leave Management Reports- Added Department",
        ],
        bugs: [],
      },
      {
        version: "V.4.6.65",
        released: "2023-10-01 08:00:00",
        features: ["Added Biotime Log status"],
        bugs: [],
      },
      {
        version: "V.4.6.64",
        released: "2023-09-28 18:00:00",
        features: ["Added User guide"],
        bugs: [],
      },
      {
        version: "V.4.6.63",
        released: "2023-09-26 16:30:00",
        features: [
          "Added encoding for new employee",
          "Leave Managment Reports- Added beginning leave balance",
        ],
        bugs: [],
      },
      {
        version: "V.4.6.62",
        released: "2023-09-25 10:28:00",
        features: [],
        bugs: ["Leave Form- Fixed sick leave filing"],
      },
      {
        version: "V.4.6.61",
        released: "2023-09-18 17:30:00",
        features: ["Leave Form- Added cancellation", "Added Profile Details"],
        bugs: [],
      },
      {
        version: "V.4.6.60",
        released: "2023-09-15 12:00:00",
        features: ["Leave Form- Added holiday validation"],
        bugs: [],
      },
      {
        version: "V.4.6.59",
        released: "2023-09-13 15:00:00",
        features: [],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.4.6.58",
        released: "2023-09-12",
        features: ["Employee Masterlist- Added Profile information"],
        bugs: ["Optimized layout design in small devices"],
      },
      {
        version: "V.4.6.57",
        released: "2023-09-11",
        features: ["Leave Form- Added Others Leave Type"],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.4.5.56",
        released: "2023-09-07",
        features: ["Leave Management Reports- Added leave credit balance"],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.4.5.55",
        released: "2023-09-06",
        features: [],
        bugs: ["Payment Request - perfomance improvements"],
      },
      {
        version: "V.4.4.54",
        released: "2023-09-04",
        features: ["Added leave form"],
        bugs: [],
      },
      {
        version: "V.3.3.53",
        released: "2023-07-26",
        features: ["Added new moderator for check audit"],
        bugs: [],
      },
      {
        version: "V.3.3.52",
        released: "2023-07-20",
        features: [
          "Payment Request Approver - Added utilization cashbond",
          "Payment Request Form - Added Reference number after submission request",
        ],
        bugs: [
          "Payment Request Form - Fixed rounding decimals",
          "Bugs fixes and perfomance improvements",
        ],
      },
      {
        version: "V.3.3.51",
        released: "2023-07-17",
        features: ["Travel Request Form  - Approver adjustment"],
        bugs: [],
      },
      {
        version: "V.3.3.50",
        released: "2023-07-13",
        features: [],
        bugs: ["Fixed logged in attempt bugs"],
      },
      {
        version: "V.3.3.49",
        released: "2023-07-11",
        features: ["Added Conference Reservation"],
        bugs: ["Optimized performance improvements"],
      },
      {
        version: "V.3.3.48",
        released: "2023-07-04",
        features: [],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.3.3.47",
        released: "2023-06-29",
        features: ["Travel Request Form - Added email notifications"],
        bugs: [],
      },
      {
        version: "V.3.3.46",
        released: "2023-06-28",
        features: ["Liquidation - update reimbursable limit to ₱1000"],
        bugs: ["Optimized layout design in small devices"],
      },
      {
        version: "V.3.3.45",
        released: "2023-06-27",
        features: [
          "Added uploading profile pictures",
          "Added notifications setting",
          "Added user activity logs",
          "Added system update logs",
        ],
        bugs: ["Bugs fixes and perfomance improvements"],
      },
      {
        version: "V.3.3.44",
        released: "2023-06-23",
        features: [],
        bugs: [
          "Fixed pre-approval issues",
          "Fixed sending wrong email recipient address",
        ],
      },
    ]);
    const dateFormat = (val) => {
      return moment(val).fromNow();
    };
    const loadCounter = () => {
      counter.value += 1;
      maxLimit.value = counter.value * showPerPage.value;
      counterPage.value += showPerPage.value;
    };
    return {
      versionList,
      dateFormat,
      loadCounter,
      counter,
      counterPage,
      maxLimit,
      showPerPage,
    };
  },
};
</script>
<style scoped>
.version-container {
  border-radius: 10px;
  font-family: "Montserrat";
  color: rgb(94, 92, 92);
  margin: 15px 0px;
}
.version-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
}
.version-details {
  line-height: 28px;
}
h3 {
  color: rgb(44, 44, 44);
}
hr {
  margin: 20px 0px;
  border-top: 0.5px solid rgb(194, 192, 192);
}
ul > li {
  margin: 0px 30px;
  font-size: 15px;
}
.version-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-load-more {
  max-width: 150px;
  border: 1px solid transparent;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: "Montserrat";
  align-items: center;
  background-clip: padding-box;
  font-weight: 600;
  justify-content: center;
  min-height: 3rem;
  text-decoration: none;
  transition: all 250ms;
  padding: 0px 20px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .version-details {
    line-height: 24px;
  }
  .version-date {
    font-size: 14px;
  }
  hr {
    margin: 15px 0px;
    border-top: 0.5px solid rgb(194, 192, 192);
  }
  ul > li {
    margin: 0px 20px;
    font-size: 13px;
  }
  h3 {
    font-size: 16px;
  }
  h4 {
    font-size: 14px;
  }
}
</style>
