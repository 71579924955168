<template>
  <Transition
    enter-active-class="animate__animated animate__slideInLeft"
    leave-active-class="animate__animated animate__slideOutLeft"
  >
    <div class="auth-card" v-if="screenWidth >= 600">
      <nav>
        <ul>
          <li
            v-for="(item, index) in items"
            :key="index"
            @click="selectedItems(index, item)"
          >
            <div
              class="item-menu"
              :class="[index === indexSelected ? 'active' : '']"
              v-if="
                item.userType.includes($store.state.getUserType) ? true : false
              "
            >
              <div class="left-navigation">
                <component :is="item.icon" class="icon-svg"> </component>
                {{ item.title }}
              </div>
              <div>
                <span
                  v-if="
                    item.notif && notification(item.name) > 0 ? true : false
                  "
                >
                  <span
                    class="notif-badge"
                    :class="[item.subItem ? 'mod-badge' : null]"
                  >
                    {{ notification(item.name) }}
                  </span>
                </span>
                <span class="arrow-right" v-show="item.subItem">
                  <chevronRightSVG v-if="!subExpanded" />
                  <chevronUpSVG v-else />
                </span>
              </div>
            </div>
            <div class="subItem-menu">
              <ul v-show="subExpanded">
                <div
                  v-for="(subItem, index) in item.subItem"
                  :key="index"
                  @click="selectedItems(subIndex, subItem)"
                >
                  <li
                    v-if="
                      !subItem.type.includes($store.state.getUserType)
                        ? true
                        : false
                    "
                  >
                    {{ subItem.title }}
                  </li>
                </div>
              </ul>
            </div>
          </li>
        </ul>
      </nav>
    </div>
    <div class="auth-card" v-else v-show="isExpand">
      <nav>
        <ul>
          <li
            v-for="(item, index) in items"
            :key="index"
            @click="selectedItems(index, item)"
          >
            <div
              class="item-menu"
              :class="[index === indexSelected ? 'active' : '']"
              v-if="
                item.userType.includes($store.state.getUserType) ? true : false
              "
            >
              <div class="left-navigation">
                <component :is="item.icon" class="icon-svg"> </component>
                {{ item.title }}
              </div>
              <div>
                <span
                  v-if="
                    item.notif && notification(item.name) > 0 ? true : false
                  "
                >
                  <span
                    class="notif-badge"
                    :class="[item.subItem ? 'mod-badge' : null]"
                  >
                    {{ notification(item.name) }}
                  </span>
                </span>
                <span class="arrow-right" v-show="item.subItem">
                  <chevronRightSVG v-if="!subExpanded" />
                  <chevronUpSVG v-else />
                </span>
              </div>
            </div>
            <div class="subItem-menu">
              <ul v-show="subExpanded">
                <div
                  v-for="(subItem, index) in item.subItem"
                  :key="index"
                  @click="selectedItems(subIndex, subItem)"
                >
                  <li
                    v-if="
                      !subItem.type.includes($store.state.getUserType)
                        ? true
                        : false
                    "
                  >
                    {{ subItem.title }}
                  </li>
                </div>
              </ul>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </Transition>
</template>
<script>
import dashboardIconSVG from "@/assets/svgs/dashboardIcon.vue";
import calendarIconSVG from "@/assets/svgs/calendarIcon.vue";
import paymentIconSVG from "@/assets/svgs/paymentIcon.vue";
import chartIconSVG from "@/assets/svgs/chartIcon.vue";
import helpIconSVG from "@/assets/svgs/helpIcon.vue";
import settingIconSVG from "@/assets/svgs/settingIcon.vue";
import activityIconSVG from "@/assets/svgs/activityIcon.vue";
import liquidationIconSVG from "@/assets/svgs/liquidationIcon.vue";
import moneyIconSVG from "@/assets/svgs/moneyIcon.vue";
import cardIconSVG from "@/assets/svgs/cardIcon.vue";
import badgeIconSVG from "@/assets/svgs/badgeIcon.vue";
import carIconSVG from "@/assets/svgs/carIcon.vue";
import timeIconSVG from "@/assets/svgs/timeIcon.vue";
import mapIconSVG from "@/assets/svgs/mapIcon.vue";
import reportIconSVG from "@/assets/svgs/reportIcon.vue";
import conferenceIconSVG from "@/assets/svgs/conferenceIcon.vue";
import chevronRightSVG from "@/assets/svgs/chevronRight.vue";
import chevronUpSVG from "@/assets/svgs/chevronUp.vue";
import { inject } from "vue";
import { reactive, ref, computed } from "vue";
export default {
  components: {
    dashboardIconSVG,
    calendarIconSVG,
    paymentIconSVG,
    chartIconSVG,
    helpIconSVG,
    settingIconSVG,
    activityIconSVG,
    liquidationIconSVG,
    moneyIconSVG,
    cardIconSVG,
    badgeIconSVG,
    carIconSVG,
    conferenceIconSVG,
    chevronRightSVG,
    chevronUpSVG,
    timeIconSVG,
    mapIconSVG,
    reportIconSVG,
  },
  setup(props, { emit }) {
    const screenWidth = screen.width;
    const isActive = ref(false);
    const store = inject("$store");
    const subExpanded = ref(false);
    const indexSelected = ref(0);
    const countNotif = ref(0);
    const items = reactive([
      {
        title: "Dashboard",
        icon: "dashboardIconSVG",
        component: "SectionAuthDashboard",
        name: "Dashboard",
        userType: [
          "super",
          "moderator",
          "semi-moderator",
          "pre-moderator",
          "post-moderator",
          "distributed-moderator",
          "reactive-moderator",
          "dispatch-moderator",
          "head",
          "operator-moderator",
          "driver-moderator",
          "checker-moderator",
          "admin-moderator",
          "supervisor-moderator",
          "cohead-moderator",
          "report-moderator",
          "head-supervisor",
          "avp-head",
        ],
      },
      {
        title: "Employees",
        icon: "calendarIconSVG",
        component: "SectionAuthEmployeeLeave",
        name: "Employee Leave",
        subItem: [
          {
            title: "Employee Masterlist",
            component: "SectionAuthEmployeeMasterlist",
            type: [],
          },
          {
            title: "Leave Management",
            component: "SectionAuthEmployeeLeaveManagement",
            type: [],
          },
          {
            title: "Shifting Schedule Data Entry",
            component: "SectionShiftingSchedule",
            type: [
              "moderator",
              "semi-moderator",
              "pre-moderator",
              "post-moderator",
              "distributed-moderator",
              "reactive-moderator",
              "dispatch-moderator",
              "head",
              "operator-moderator",
              "driver-moderator",
              "checker-moderator",
              "supervisor-moderator",
              "super",
              "avp-head",
            ],
          },
        ],
        userType: [
          "super",
          "moderator",
          "semi-moderator",
          "pre-moderator",
          "head",
          "admin-moderator",
          "supervisor-moderator",
          "avp-head",
        ],
        notif: true,
      },
      {
        title: "Leave Request",
        icon: "calendarIconSVG",
        component: "SectionAuthEmployeeLeaveManagement",
        name: "Employee Leave",
        userType: ["operator-moderator", "head-supervisor"],
        notif: true,
      },
      {
        title: "Payment Request",
        icon: "paymentIconSVG",
        component: "SectionAuthPaymentManagement",
        name: "Payment Request",
        userType: [
          "super",
          "moderator",
          "semi-moderator",
          "pre-moderator",
          "checker-moderator",
        ],
        notif: true,
      },

      {
        title: "Overtime Request",
        icon: "timeIconSVG",
        component: "SectionAuthOvertime",
        name: "Overtime Request",
        userType: [
          "head",
          "cohead-moderator",
          "admin-moderator",
          "operator-moderator",
          "semi-moderator",
          "supervisor-moderator",
          "moderator",
          "pre-moderator",
          "super",
          "head-supervisor",
          "avp-head",
        ],
        notif: true,
      },
      {
        title: "Official Business Request",
        icon: "mapIconSVG",
        component: "SectionAuthOfficialBusiness",
        name: "Official Business Request",
        userType: [
          "head",
          "cohead-moderator",
          "admin-moderator",
          "operator-moderator",
          "semi-moderator",
          "supervisor-moderator",
          "moderator",
          "pre-moderator",
          "super",
          "head-supervisor",
          "avp-head",
        ],
        notif: true,
      },
      {
        title: "Payment Preparation",
        icon: "cardIconSVG",
        component: "SectionAuthPaymentPreparation",
        name: "Payment Preparation",
        userType: ["pre-moderator", "post-moderator"],
        notif: true,
      },
      {
        title: "Checking Audit",
        icon: "badgeIconSVG",
        component: "SectionAuthPaymentPreparation",
        name: "Checking Audit",
        userType: ["assurance-moderator", "reactive-moderator"],
      },
      {
        title: "Releasing of Payment",
        icon: "moneyIconSVG",
        component: "SectionAuthPaymentReleased",
        name: "Releasing of Payment",
        userType: ["distributed-moderator", "dispatch-moderator"],
        notif: true,
      },
      {
        title: "Payment Liquidation",
        icon: "liquidationIconSVG",
        component: "SectionAuthPaymentLiquidation",
        name: "Payment Liquidation",
        userType: [
          "distributed-moderator",
          "reactive-moderator",
          "assurance-moderator",
        ],
        notif: true,
      },
      {
        title: "Travel Request",
        icon: "carIconSVG",
        component: "SectionAuthTravel",
        name: "Travel Request",
        userType: [
          "operator-moderator",
          "head",
          "moderator",
          "super",
          "avp-head",
        ],
        notif: true,
      },
      {
        title: "My Assignments",
        icon: "carIconSVG",
        component: "SectionAuthTravel",
        name: "Travel Request",
        userType: ["driver-moderator"],
        notif: true,
      },
      {
        title: "Vehicle/Driver Assignment",
        icon: "carIconSVG",
        component: "SectionAuthTravelSchedule",
        name: "Vehicle/Driver Assignment",
        userType: ["operator-moderator"],
      },
      {
        title: "Conference Reservation",
        icon: "conferenceIconSVG",
        component: "SectionAuthConferenceReservation",
        name: "Conference Reservation",
        userType: ["checker-moderator"],
        notif: true,
      },
      {
        title: "Payment Analytics",
        icon: "chartIconSVG",
        component: "SectionAuthPaymentAnalytics",
        name: "Payment Analytics",
        userType: ["super"],
      },
      {
        title: "Travel Analytics",
        icon: "chartIconSVG",
        component: "SectionAuthTravelAnalytics",
        name: "Travel Analytics",
        userType: [],
      },
      {
        title: "Reports",
        icon: "reportIconSVG",
        component: "SectionAuthEmployeeLeaveReport",
        name: "Reports",
        userType: ["admin-moderator", "report-moderator"],
      },
      {
        title: "Activity Logs",
        icon: "activityIconSVG",
        component: "SectionAuthActivityLog",
        name: "Activity Logs",
        userType: ["super"],
      },
      {
        title: "Account Setting",
        icon: "settingIconSVG",
        component: "SectionAuthAccountSetting",
        name: "Account Setting",
        userType: [
          "super",
          "moderator",
          "semi-moderator",
          "pre-moderator",
          "post-moderator",
          "distributed-moderator",
          "reactive-moderator",
          "dispatch-moderator",
          "head",
          "operator-moderator",
          "driver-moderator",
          "checker-moderator",
          "admin-moderator",
          "supervisor-moderator",
        ],
      },
      {
        title: "System About",
        icon: "helpIconSVG",
        component: "SectionAuthSystemDetails",
        name: "System About",
        userType: [],
      },
      {
        title: "Demo Chart",
        icon: "chartIconSVG",
        component: "SectionDemoChart",
        name: "Demo Chart",
        userType: [],
      },
    ]);
    const isExpand = computed(() => store.getters.appExpand);
    const selectedItems = (index, item) => {
      indexSelected.value = index;
      isActive.value = true;

      items.filter((val) => {
        if (val.subItem && val.title === item.title) {
          subExpanded.value = !subExpanded.value;
        }
      });

      let component = item.component;
      let title = item.title;

      if (component !== "SectionAuthEmployeeLeave") {
        store.state.authTitlePage = title;
        store.state.isExpandMenu = false;
        emit("passComponent", component);
      }
    };
    const notification = (name) => {
      if (name === "Payment Liquidation") {
        return store.state.countLiquidationRequest;
      }

      if (name === "Payment Request") {
        return store.state.countPaymentRequest;
      }

      if (name === "Payment Preparation") {
        return store.state.countPreparationRequest;
      }

      if (name === "Releasing of Payment") {
        return store.state.countReleaseRequest;
      }

      if (name === "Travel Request") {
        return store.state.countTravelRequest;
      }

      if (name === "Conference Reservation") {
        return store.state.countConference;
      }

      if (name === "Employee Leave") {
        return store.state.countLeaveRequest;
      }

      if (name === "Overtime Request") {
        return store.state.countOvertimeRequest;
      }

      if (name === "Official Business Request") {
        return store.state.countOfficialBusinessRequest;
      }
    };
    return {
      items,
      selectedItems,
      notification,
      indexSelected,
      isActive,
      isExpand,
      subExpanded,
      screenWidth,
      countNotif,
    };
  },
};
</script>
<style scoped>
.auth-card {
  margin-top: 15px;
}
nav {
  font-size: 15px;
  font-style: normal;
  font-family: "Montserrat";
  color: var(--lightFont);
  padding: 24px 0px;
}
nav > ul {
  list-style: none;
}
nav > ul > li {
  margin: 9px 40px;
  border-radius: 10px;
}
nav > ul > li .item-menu {
  border-radius: 10px;
  min-height: 20px;
  padding: 14px 14px 10px 24px;
  display: flex;
  justify-content: space-between;
}
.subItem-menu ul > div > li:hover,
nav > ul > li .item-menu:hover {
  background-color: #f8a334;
  color: #fff;
  cursor: pointer;
}
.icon-svg {
  position: relative;
  top: -1.6px;
  padding-right: 4px;
}
.active {
  background-color: #fa7319;
  color: #fff;
}
span .notif-badge {
  margin-left: 10px;
  padding: 5px 10px;
  border-radius: 50%;
  background-color: #e4491a;
  color: #fff;
  font-weight: 400;
  font-size: 11px;
  font-family: "Montserrat";
}
.subItem-menu ul {
  list-style: none;
  margin: 5px 5px 5px 55px;
}
.subItem-menu ul > div > li {
  margin: 12px 0px;
  padding: 8px 8px 8px 15px;
  border-radius: 10px;
}
.left-navigation {
  display: flex;
  gap: 10px;
}
.cheveron {
  transform: rotate(0);
}
.rotate {
  transform: rotate(-0.25turn);
}
.mod-badge {
  position: relative;
  top: -8px;
}
@media only screen and (max-width: 600px) {
  .auth-card {
    margin-top: 0px;
    border-radius: 0px;
    position: absolute;
    z-index: 1;
    top: 52px;
    left: 0px;
    right: 60px;
    min-width: 100%;
    height: 100vh;
  }
}
</style>
