<template>
  <app-auth-title />
  <div class="admin-content">
    <div class="admin-grid">
      <div class="search-bar">
        <input
          type="text"
          placeholder="search..."
          v-model="searchValue"
          @input="getFilterAction(selectedEntry)"
        />
      </div>
      <div class="show-entries">
        Show
        <select
          v-model="selectedEntry"
          class="item-entries"
          @change="getFilterAction(selectedEntry)"
        >
          <option v-for="item in showEntries" :key="item">
            {{ item }}
          </option>
        </select>
        Entries
      </div>
      <div class="filter">
        Filter by Intended Date:
        <select
          v-model="selectedFilter"
          class="item-entries"
          @change="getFilterAction(selectedEntry)"
        >
          <option v-for="item in filterDate" :key="item">
            {{ item }}
          </option>
        </select>
        <select
          v-if="$store.state.getUserType === 'admin-moderator'"
          v-model="selectedDepartment"
          class="item-entries md-status"
          @change="getFilterAction(selectedEntry)"
        >
          <option>All</option>
          <option v-for="item in departmentItem" :key="item">
            {{ item.deptName }}
          </option>
        </select>
        <select
          v-model="selectedLeaveType"
          class="item-entries md-status"
          @change="getFilterAction(selectedEntry)"
        >
          <option v-for="item in leaveTypesItem" :key="item">
            {{ item }}
          </option>
        </select>
        <select
          v-model="selectedStatus"
          class="item-entries md-status"
          @change="getFilterAction(selectedEntry)"
        >
          <option v-for="item in filterStat" :key="item">
            {{ item }}
          </option>
        </select>
      </div>

      <div class="payment-grid">
        <div v-if="isNotFound">
          <h3>{{ messageNotFound }}</h3>
        </div>
        <div v-if="isRender" class="waiting">
          <img :src="require(`@/assets/images/waiting.gif`)" alt="icon" />
        </div>
        <div v-else v-for="(transaction, index) in filterItem" :key="index">
          <div class="admin-card accordion">
            <div style="display: flex">
              <div class="accordion-content">
                <h4>
                  {{ convertToWord(transaction.leaveType) }}
                  <span v-if="transaction.leaveType === 'AWOP'">{{
                    convertToWord(null, transaction.awopType)
                  }}</span>
                </h4>
                <div>
                  <span class="card-date subtitle"
                    >Date Intended:
                    {{ dateFormat(transaction.dateStart, "DI") }}
                  </span>
                </div>
                <div>
                  <span class="card-date subtitle"
                    >Number of days: {{ transaction.numberDays }}
                  </span>
                </div>
                <div>
                  <span class="card-date subtitle"
                    >Requested by: {{ transaction.requestedBy }}
                  </span>
                </div>
                <div>
                  <span class="card-date subtitle"
                    >Requested date:
                    {{ dateFormat(transaction.createdAt, "DR") }}
                  </span>
                </div>
              </div>
            </div>

            <div>
              <input
                @click="routeModal(index)"
                type="submit"
                :value="
                  !transaction.approverStatus
                    ? 'Pending'
                    : transaction.approverStatus
                "
                class="btn-status"
                :class="[
                  !transaction.approverStatus
                    ? 'status-pending'
                    : transaction.approverStatus === 'Pre-approved'
                    ? 'status-pre-approved'
                    : transaction.approverStatus === 'Approved'
                    ? 'status-approved'
                    : transaction.approverStatus === 'Cancelled'
                    ? 'status-cancelled'
                    : transaction.approverStatus === 'Disapproved'
                    ? 'status-declined'
                    : 'status-revert',
                ]"
              />
            </div>
          </div>
          <div
            class="panel"
            v-if="index === indexSelected && ifExpand ? true : false"
          >
            <SectionEmployeeStatus
              :transaction="transaction"
            ></SectionEmployeeStatus>
            <div class="panel-payment-details">
              <SectionEmployeeLeaveDetails
                :transaction="transaction"
              ></SectionEmployeeLeaveDetails>
              <div class="btn-action-container">
                <input
                  v-if="actionApprover(transaction)"
                  class="btn btn-action"
                  type="submit"
                  value="Action"
                  @click="isModal = true"
                />
                <input
                  class="btn btn-close"
                  type="submit"
                  value="Close"
                  @click="ifExpand = false"
                />
              </div>
              <div v-if="isModal">
                <app-modal-leave-approval
                  :requestId="transaction.id"
                  :dataItems="transaction"
                  :transaction="transactions"
                  :employeeItems="employeeList"
                  @passClose="getValue"
                ></app-modal-leave-approval>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-record">
          <div>Total Record(s): {{ total_record }}</div>
        </div>
        <div class="pagination">
          <div class="previous">
            <input
              type="submit"
              class="prev-btn btn"
              value="Previous"
              @click="prevRecords(current_page)"
            />
          </div>

          <div class="pages">
            <span
              >Page {{ total_record ? current_page : 0 }} of
              {{ total_page }}
            </span>
          </div>
          <div class="next">
            <input
              :disabled="total_page === current_page ? true : false"
              type="submit"
              class="nxt-btn btn"
              value="Next"
              @click="nextRecords(current_page)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionEmployeeLeaveDetails from "@/components/partials/employees/AuthEmployeeLeaveDetails.vue";
import SectionEmployeeStatus from "@/components/partials/employees/AuthEmployeeStatusTracker.vue";
import { ref, computed, onActivated } from "vue";
import { inject } from "vue";
export default {
  components: {
    SectionEmployeeLeaveDetails,
    SectionEmployeeStatus,
  },
  setup() {
    const axios = inject("$axios");
    const store = inject("$store");
    const moment = inject("$moment");
    const isModal = ref(false);
    const ifExpand = ref(false);
    const indexSelected = ref(0);
    const searchValue = ref("");
    const current_page = ref(1);
    const per_page = ref(10);
    const total_record = ref(0);
    const total_page = ref(0);
    const modified = ref([]);
    const messageNotFound = ref("");
    const isNotFound = ref(false);
    const showEntries = ref([5, 10, 25, 50]);
    const showFilterDate = ref([
      "Today",
      "Incoming",
      "This Month",
      "Last 7 Days",
      "Last Month",
      "Year to Date",
      "All Time",
    ]);
    const showFilterStatus = ref([
      "All",
      "Pending",
      "Pre-approved",
      "Approved",
      "Disapproved",
      "Cancelled",
    ]);
    const selectedEntry = ref(5);
    const total_amount = ref(0);
    const transactions = ref([]);
    const fetchItemList = ref([]);
    const employeeList = ref([]);
    const selectedFilter = ref("All Time");
    const selectedStatus = ref("Pending");
    const selectedLeaveType = ref("All");
    const selectedDepartment = ref("All");
    const expandPanel = ref(false);
    const isRender = ref(true);
    const leaveTypesItem = ref([
      "All",
      "Vacation Leave",
      "Sick Leave",
      "Emergency Leave",
      "Absent without pay",
      "Compassionate Leave",
      "Birthday Leave",
      "Maternity Leave",
      "Paternity Leave",
    ]);
    const departmentItem = ref([]);
    const filterItem = computed(() => {
      return modified.value;
    });
    const filterStat = computed(() => {
      return showFilterStatus.value.filter((stat) => {
        if (store.state.getUserType === "admin-moderator") {
          return stat !== "Pre-approved";
        } else {
          return stat;
        }
      });
    });
    const filterDate = computed(() => {
      return showFilterDate.value.filter((stat) => {
        return stat;
      });
    });
    onActivated(() => {
      getUserItem();
      getDepartment();
      getAPIrequest();
    });

    const routeModal = (val) => {
      indexSelected.value = val;
      ifExpand.value = !ifExpand.value;
    };
    const actionApprover = (val) => {
      if (store.state.getUserType === "admin-moderator") {
        return (
          val.approverStatus === "Pre-approved" ||
          val.approverStatus === "Approved"
        );
      } else {
        return !val.approverStatus;
      }
    };
    const getValue = (
      val,
      payload,
      reqId,
      status,
      hrDtAdded,
      superiorDtAdded,
      approverRemarks,
      approver
    ) => {
      isModal.value = val;
      if (payload) {
        setTimeout(async () => {
          let itemData;

          itemData = transactions.value.find((val) => {
            return val.id === reqId;
          });

          itemData.approverStatus = status;
          itemData.approverDtAdded = hrDtAdded;
          itemData.superiorDtAdded = superiorDtAdded;
          itemData.approverRemarks = approverRemarks;
          itemData.approverBy = approver;

          getFilterAction();
          store.commit("notificationCounterLeave", transactions.value);

          expandPanel.value = false;
          ifExpand.value = false;
        }, 2000);
      }
    };
    const convertToWord = (val, subVal) => {
      let abbreviation = val;
      let awopAbbrev = subVal;
      let word;
      if (val) {
        if (abbreviation === "SL") {
          word = "Sick Leave";
        }
        if (abbreviation === "VL") {
          word = "Vacation Leave";
        }
        if (abbreviation === "EL") {
          word = "Emergency Leave";
        }
        if (abbreviation === "AWOP") {
          word = "Absent without pay";
        }
        if (abbreviation === "CL") {
          word = "Compassionate Leave";
        }
        if (abbreviation === "ML") {
          word = "Maternity Leave";
        }
        if (abbreviation === "PL") {
          word = "Paternity Leave";
        }
        if (abbreviation === "BL") {
          word = "Birthday Leave";
        }
        if (abbreviation === "SPL") {
          word = "Solo Parent Leave";
        }
        if (abbreviation === "MCW") {
          word = "Magna Carta for Women";
        }
        if (abbreviation === "VAWC") {
          word = "Violence Againts Women and their Children";
        }
        return word;
      } else {
        if (awopAbbrev === "AWOPSL") {
          word = "(Sick Leave)";
        }
        if (awopAbbrev === "AWOPVL") {
          word = "(Vacation Leave)";
        }
        return word;
      }
    };

    const getFilterAction = (val) => {
      let selectedEntry = val;
      fetchItemList.value = transactions.value
        .filter((post) => {
          let referenceNumber = post.id + 100000;
          referenceNumber = referenceNumber.toString();
          return (
            post.requestedBy
              .toLowerCase()
              .includes(searchValue.value.toLowerCase()) ||
            referenceNumber
              .toLowerCase()
              .includes(searchValue.value.toLowerCase())
          );
        })
        .filter((val) => {
          let dateSelected;
          const currentDate = store.state.currentDate;
          const lastSevenDays = moment(currentDate)
            .subtract(7, "days")
            .format("YYYY-MM-DD");

          const thisMonth = moment(currentDate)
            .subtract(moment().format("DD"), "days")
            .format("YYYY-MM-DD");

          const lastMonth = moment(currentDate)
            .subtract(1, "months")
            .format("YYYY-MM");

          const yearDate = moment(currentDate).format("YYYY");

          if (selectedFilter.value === "Today") {
            dateSelected = val.dateStart === currentDate;
          } else if (selectedFilter.value === "Last 7 Days") {
            dateSelected = val.dateStart > lastSevenDays;
          } else if (selectedFilter.value === "This Month") {
            dateSelected = val.dateStart > thisMonth;
          } else if (selectedFilter.value === "Last Month") {
            dateSelected =
              moment(val.dateStart).format("YYYY-MM") === lastMonth;
          } else if (selectedFilter.value === "Year to Date") {
            dateSelected = moment(val.dateStart).format("YYYY") === yearDate;
          } else if (selectedFilter.value === "Incoming") {
            dateSelected = val.dateStart > currentDate;
          } else {
            dateSelected = val;
          }
          return dateSelected;
        })
        .filter((role) => {
          let userLogIn = store.state.getUserType;
          let userType;

          switch (userLogIn) {
            case "super":
              userType =
                role.isDirect || role.department === "Executive Office";
              break;
            case "admin-moderator":
              userType = role.isPreapproved;
              break;
            case "moderator":
              userType =
                (role.department === "Health Provider & Patient Relations" ||
                  role.department === "Marketing") &&
                role.requestedBy !== store.state.getUserLog;
              break;
            case "pre-moderator":
              userType =
                role.department === "Accounting" &&
                role.requestedBy !== store.state.getUserLog;
              break;
            case "semi-moderator":
              userType =
                (role.department === "Corporate Services" ||
                  role.department === "Astral Clinic" ||
                  role.empId === 72) &&
                role.User.designation !== "Messenger" &&
                role.User.designation !== "Company Driver" &&
                role.User.designation !== "Utility Worker" &&
                role.User.designation !== "Policy Administration Assistant" &&
                role.User.designation !== "Policy and Admin Support Staff" &&
                role.User.designation !== "Policy Insurance Coordinator" &&
                role.requestedBy !== store.state.getUserLog;
              break;
            case "supervisor-moderator":
              userType =
                role.department === "Muntinlupa Clinic" &&
                role.requestedBy !== store.state.getUserLog;
              break;
            case "operator-moderator":
              userType =
                role.department === "Corporate Services" &&
                (role.User.designation === "Messenger" ||
                  role.User.designation === "Company Driver" ||
                  role.User.designation === "Utility Worker") &&
                role.requestedBy !== store.state.getUserLog;
              break;
            case "head-supervisor":
              userType =
                store.state.getDepartment === "Corporate Services" &&
                (role.User.designation === "Policy Administration Assistant" ||
                  role.User.designation === "Policy and Admin Support Staff" ||
                  role.User.designation === "Policy Insurance Coordinator") &&
                role.requestedBy !== store.state.getUserLog;

              break;
            case "head":
              userType =
                role.department === store.state.getDepartment &&
                role.requestedBy !== store.state.getUserLog;

              break;

            case "avp-head":
              userType =
                role.department === store.state.getDepartment &&
                role.requestedBy !== store.state.getUserLog;

              break;
            default:
              userType;
          }
          return userType;
        })
        .filter((stat) => {
          let leaveType;
          let abbreviation;

          if (selectedLeaveType.value === "All") {
            leaveType = stat;
          } else {
            if (selectedLeaveType.value === "Sick Leave") {
              abbreviation = "SL";
            }
            if (selectedLeaveType.value === "Vacation Leave") {
              abbreviation = "VL";
            }
            if (selectedLeaveType.value === "Emergency Leave") {
              abbreviation = "EL";
            }
            if (selectedLeaveType.value === "Absent without pay") {
              abbreviation = "AWOP";
            }
            if (selectedLeaveType.value === "Compassionate Leave") {
              abbreviation = "CL";
            }
            if (selectedLeaveType.value === "Maternity Leave") {
              abbreviation = "ML";
            }
            if (selectedLeaveType.value === "Paternity Leave") {
              abbreviation = "PL";
            }
            if (selectedLeaveType.value === "Birthday Leave") {
              abbreviation = "BL";
            }
            leaveType = stat.leaveType === abbreviation;
          }
          return leaveType;
        })
        .filter((stat) => {
          let status;

          if (selectedStatus.value === "All") {
            status = stat;
          } else if (selectedStatus.value === "Pending") {
            if (store.state.getUserType === "admin-moderator") {
              status = stat.approverStatus === "Pre-approved";
            } else {
              status = !stat.approverStatus;
            }
          } else {
            status = stat.approverStatus === selectedStatus.value;
          }
          return status;
        })
        .filter((item) => {
          let department = selectedDepartment.value;
          let result;
          if (department === "All") {
            result = item;
          } else {
            result = department === item.department;
          }

          return result;
        });
      pagination(0, selectedEntry);
    };
    const pagination = (page, pr_page) => {
      let data = fetchItemList.value;
      current_page.value = page || 1;
      per_page.value = pr_page || 5;
      const offset = (current_page.value - 1) * per_page.value;
      const paginatedItems = data.slice(offset).slice(0, per_page.value);
      total_page.value = Math.ceil(data.length / per_page.value);
      modified.value = paginatedItems;
      isNotFound.value = !modified.value.length ? true : false;
      messageNotFound.value = searchValue.value
        ? `We couldn't find anything for ${searchValue.value.toLowerCase()}. Try a new search.`
        : `No transactions yet. Want to try again with different status?`;

      total_record.value = data.length;
    };
    const prevRecords = (val) => {
      let page = val - 1 ? val - 1 : null;
      let currentEntryPage = selectedEntry.value;
      pagination(page, currentEntryPage);
    };
    const nextRecords = (val) => {
      let page = total_page.value > val ? val + 1 : null;
      let currentEntryPage = selectedEntry.value;
      pagination(page, currentEntryPage);
    };
    const getAPIrequest = async () => {
      try {
        const response = await axios.get(`/api/admin/employee/leave`);
        isNotFound.value = response.data.length ? false : true;
        transactions.value = response.data;
        store.commit("notificationCounterLeave", null);
        getFilterAction();
        isRender.value = false;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const dateFormat = (val, stat) => {
      if (stat === "DI") {
        return val ? moment(val).format("ll") : "-";
      } else {
        return val ? moment(val).format("LLL") : "-";
      }
    };
    const dayFormat = (val) => {
      return val ? moment(val).format("dddd") : "-";
    };
    const getDepartment = async () => {
      try {
        const response = await axios.get("/api/form/dept");
        departmentItem.value = response.data;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    const getUserItem = async () => {
      try {
        const response = await axios.get("/api/getUsers");
        store.state.employeeMasterList = response.data;
        employeeList.value = store.state.employeeMasterList;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    return {
      isModal,
      showEntries,
      selectedEntry,
      searchValue,
      transactions,
      fetchItemList,
      selectedStatus,
      showFilterStatus,
      ifExpand,
      indexSelected,
      filterItem,
      current_page,
      per_page,
      total_page,
      total_record,
      modified,
      messageNotFound,
      isNotFound,
      total_amount,
      selectedFilter,
      selectedLeaveType,
      showFilterDate,
      expandPanel,
      filterStat,
      filterDate,
      prevRecords,
      nextRecords,
      actionApprover,
      getFilterAction,
      routeModal,
      pagination,
      getAPIrequest,
      dateFormat,
      dayFormat,
      getValue,
      convertToWord,
      leaveTypesItem,
      selectedDepartment,
      getDepartment,
      departmentItem,
      getUserItem,
      employeeList,
      isRender,
    };
  },
};
</script>

<style scoped>
.subtitle {
  color: rgb(49, 48, 48);
  font-weight: 500;
}
.status-pre-approved {
  background-color: #3496e6;
}
.status-pre-approved:hover,
.status-pre-approved:focus {
  background-color: #3496e6;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  cursor: pointer;
}
.status-pre-approved:active {
  background-color: #0073df;
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  transform: translateY(0);
}

.status-pending {
  background-color: #f09409;
}

.status-approved {
  background-color: #1d9405;
}
.status-approved:hover,
.status-approved:focus {
  background-color: #1d9405;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  cursor: pointer;
}
.status-approved:active {
  background-color: #0e5f04;
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  transform: translateY(0);
}

.status-declined {
  background-color: #ad1105;
}
.status-declined:hover,
.status-declined:focus {
  background-color: #ad1105;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  cursor: pointer;
}
.status-declined:active {
  background-color: #81130b;
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  transform: translateY(0);
}
</style>
